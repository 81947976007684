define("ember-date-fns/helpers/date-format", ["exports", "@ember/component/helper", "date-fns/format"], function (_exports, _helper, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;
  /**
    Return the formatted date string in the given format.
    @method dateFormat
    @static
    @for date-fns/date-format
    @param {Date|String|Number} date the original date
    @param {String} format the string of tokens
    @return {String} the formatted date string
    @public
  */
  function dateFormat([date, format]) {
    return (0, _format.default)(date, format);
  }
  var _default = (0, _helper.helper)(dateFormat);
  _exports.default = _default;
});