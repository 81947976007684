define("ember-date-fns/helpers/date-from-now", ["exports", "@ember/component/helper", "date-fns/distance_in_words_to_now"], function (_exports, _helper, _distance_in_words_to_now) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFromNow = dateFromNow;
  _exports.default = void 0;
  /**
    Return the distance between the given date and now in words.
    @method dateFromNow
    @static
    @for date-fns/date-fom-now
    @param {Date|String|Number} date the given date
    @param {Object} options the object with options
    @return {String} the distance in words
    @public
  */
  function dateFromNow([date], options = {}) {
    return (0, _distance_in_words_to_now.default)(date, options);
  }
  var _default = (0, _helper.helper)(dateFromNow);
  _exports.default = _default;
});